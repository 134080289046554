import * as React from 'react';
import SimpleNavbar from '../../components/navbar/SimpleNavbar';
import HeroReferral from '../../components/hero/HeroReferral';
import SimpleFooter from '../../components/footer/SimpleFooter';
import FileDownload from '../../components/referralContents/FileDownload';
import SEO from '../../SEO';

export default function GuideDownload() {
  return (
    <>
      <SEO
        title="DepositoBPR Referral Program | Download Panduan Program"
        metaDescription="DepositoBPR Download Panduan Program Referral #AjakTemanDapatBonus"
      />
      <div className="flex flex-col items-center">
        <div className="w-full sm:w-96 bg-blue-light40">
          <SimpleNavbar />
          <main className="flex flex-col gap-y-4 my-4">
            <article className="relative flex flex-col items-center gap-y-4 py-6">
              <HeroReferral />
            </article>
            <FileDownload />
          </main>
          <SimpleFooter />
        </div>
      </div>
    </>
  );
}
