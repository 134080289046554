import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faClose } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import ReferralCard from '../card/ReferralCard';

const files = [
  {
    title: 'Panduan Program',
    fileName: 'Panduan Program.pdf',
    type: 'files',
    path: '915168d3bb4e535606a074547e6b49cf3b309ebe.pdf',
  },
  {
    title: 'Brosur Promosi',
    fileName: 'Brosur Promosi.png',
    type: 'images',
    path: 'd368b02a2ad68262e05cc924791c04115c929fbf-1484x2000.png',
  },
  {
    title: 'Cover Referral Program',
    fileName: 'Cover Referral Program.png',
    type: 'images',
    path: 'ad36f98e1e923913743cae8ff96a2eae374c053b-2400x1500.png',
  },
  {
    title: 'Konten Instagram Feed & Story',
    fileName: 'Konten Instagram Feed & Story.zip',
    type: 'files',
    path: 'f8df472c1f76140eee0666eea5df66ca92fc24ca.zip',
  },
  {
    title: 'Template Teks Promosi',
    fileName: 'Template Teks Promosi.docx',
    type: 'files',
    path: 'ba88aa605e9171239d2e745325f771061c4ecf31.docx',
  },
];

const allFiles = {
  fileName: 'Aset Referral Ajak Teman Dapat Bonus.zip',
  type: 'files',
  path: '3f4aa76af69d8f205ae20343ac01cecf66dbac6a.zip',
};

export default function FileDownload(props) {
  const handleDownload = (path, type, fileName) => {
    fetch(`
      ${type === 'files' ? '/sanity-cdn-files' : '/sanity-cdn-images'}/${path}
    `)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return res.blob();
      })
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      })
      .catch((err) => console.log(err, '<<ERR'));
  };

  return (
    <div className="flex flex-col items-center mx-4 relative">
      <FontAwesomeIcon
        icon={faClose}
        className="hidden md:block cursor-pointer absolute top-0 right-0 w-10 h-10 text-red"
        onClick={props.onClose}
      />
      <StaticImage
        src="../../images/miss-bee-2.webp"
        alt="miss-bee"
        objectFit="contain"
        className="w-60 z-10"
      />
      <ReferralCard
        className="-mt-12 z-20 w-full"
        title="Download Panduan Program"
      >
        <div className="flex flex-col items-center gap-y-4 px-4 py-6">
          {files.map((file) => (
            <div
              className="flex justify-between w-full rounded-lg py-3 px-4 shadow-[0px_0px_3.799999952316284px_0px_#00000040]"
              key={file.title}
            >
              <h6 className="text-sm font-normal">{file.title}</h6>
              <button
                type="button"
                onClick={() => handleDownload(file.path, file.type, file.fileName)}
              >
                <StaticImage
                  src="../../images/referral/download.svg"
                  alt="download"
                  className="w-6 h-6"
                  objectFit="contain"
                />
              </button>
            </div>
          ))}
          <button
            type="button"
            className="w-full bg-blue-light40 flex justify-between items-center p-4 text-sm text-white font-medium rounded-lg"
            onClick={() => handleDownload(allFiles.path, allFiles.type, allFiles.fileName)}
          >
            Download Semua File
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </ReferralCard>
    </div>
  );
}

FileDownload.propTypes = {
  onClose: PropTypes.func,
};

FileDownload.defaultProps = {
  onClose: undefined,
};
