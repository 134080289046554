import React from 'react';
import { Script, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ONELINK_SCRIPT_URL } from '../../configs/appsflyer';
import useSmartScript from '../../hooks/useSmartScript';

export default function SimpleNavbar() {
  const { setIsSmartScriptLoaded } = useSmartScript('referral');

  return (
    <>
      <Script src={ONELINK_SCRIPT_URL} onLoad={() => { setIsSmartScriptLoaded(true); }} />
      <nav className="w-full p-4 md:px-8 lg:px-60 md:py-4 sticky top-0 bg-white z-40">
        <Link to="/">
          <StaticImage
            src="../../images/LogoKomunal.jpg"
            alt="Komunal-Logo"
            className="w-36 h-auto"
            objectFit="contain"
            placeholder="none"
          />
        </Link>
      </nav>
    </>
  );
}
