import React from 'react';
import PropTypes from 'prop-types';

export default function ReferralCard(props) {
  return (
    <article className={`bg-white border-[1px] border-green-light40 rounded-lg ${props.className ? props.className : ''}`}>
      <h2 className="text-md text-center text-blue-light40 font-semibold bg-gradient-to-b from-yellow to-yellow-100 rounded-b-3xl mx-4 p-2">
        {props.title}
      </h2>
      {props.children}
    </article>
  );
}

ReferralCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ReferralCard.defaultProps = {
  className: null,
};
